export default class EstablishmentRolesFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.textSearch = "";
    this.userTypeToken = "";
  }
  fillData(data) {
    this.textSearch = data.textSearch;
    this.userTypeToken = data.userTypeToken;
  }
}
