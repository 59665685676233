// import { ifAuthenticated, ifNotAuthenticated } from "./../utils/functions";
import { DEFAULT_PAGES } from "./../utils/constants";
import { USER_TYPE } from "./../utils/constantLists";
import { checkPrivilege } from "./../utils/functions";
import i18n from "./../i18n";
import store from "./../store";

export const ifAuthenticated = (to, from, next) => {
  if (store.getters.userAuthorizeToken) {
    next({ name: DEFAULT_PAGES.loggedIn });
  } else {
    next();
  }
};
export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.userAuthorizeToken) {
    next({ name: DEFAULT_PAGES.notLoggedIn });
  } else {
    next();
  }
};

export const handlePrivilege = (to, from, next, privilegeStatus) => {
  ifNotAuthenticated(to, from, next);
  if (privilegeStatus) {
    next();
  } else {
    next({ name: "Error404" });
  }
};

const routes = [
  //#region General
  {
    path: "/test",
    name: "Test",
    component: () => import("../views/general/Test.vue"),
    meta: {
      pageName: "test",
      pageIcon: "homepage.svg",
      currentPage: "home",
      currentSubPage: "",
    },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/home/Home.vue"),
    beforeEnter: ifAuthenticated,
    meta: {
      pageName: i18n.t("homePage"),
      pageIcon: "homepage.svg",
      currentPage: "home",
      currentSubPage: "",
    },
  },
  //#endregion General

  //#region Auth
  {
    path: "/auth/login",
    name: "UserLogin",
    component: () => import("../views/users/UserLogin"),
    beforeEnter: ifAuthenticated,
    meta: {
      pageName: i18n.t("login"),
      pageIcon: "homepage.svg",
      currentPage: "home",
      currentSubPage: "",
    },
  },
  //#endregion Auth

  //#region Users
  //#region MasterAdmins
  {
    path: "/users/masterAdmins",
    name: "MasterAdmins",
    component: () => import("../views/users/MasterAdmins.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeMasterAdmins.viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("masterAdmins.modelName"),
      pageIcon: "masterAdmins.svg",
      currentPage: "users",
      currentSubPage: "masterAdmins",
      userTypeToken: USER_TYPE.MasterAdmin,
      pageGet: "MasterAdmins",
      pageAdd: "MasterAdminAdd",
      pageEdit: "MasterAdminEdit",
    },
  },
  {
    path: "/users/masterAdmins/add",
    name: "MasterAdminAdd",
    component: () => import("../views/users/UserAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeMasterAdmins.add
        )
      );
    },
    meta: {
      pageName: i18n.t("masterAdmins.add"),
      pageIcon: "masterAdmins.svg",
      currentPage: "users",
      currentSubPage: "masterAdmins",
      userTypeToken: USER_TYPE.MasterAdmin,
      pageGet: "MasterAdmins",
      pageAdd: "MasterAdminAdd",
      pageEdit: "MasterAdminEdit",
    },
  },
  {
    path: "/users/masterAdmins/edit",
    name: "MasterAdminEdit",
    component: () => import("../views/users/UserEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeMasterAdmins.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("masterAdmins.edit"),
      pageIcon: "masterAdmins.svg",
      currentPage: "users",
      currentSubPage: "employees",
      userTypeToken: USER_TYPE.MasterAdmin,
      pageGet: "MasterAdmins",
      pageAdd: "MasterAdminAdd",
      pageEdit: "MasterAdminEdit",
    },
  },
  //#endregion MasterAdmins

  //#region Employees
  {
    path: "/users/employees",
    name: "Employees",
    component: () => import("../views/users/Employees.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeEmployees.viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("employees.modelName"),
      pageIcon: "employees.svg",
      currentPage: "users",
      currentSubPage: "employees",
      userTypeToken: USER_TYPE.Employee,
      pageGet: "Employees",
      pageAdd: "EmployeeAdd",
      pageEdit: "EmployeeEdit",
    },
  },
  {
    path: "/users/employees/add",
    name: "EmployeeAdd",
    component: () => import("../views/users/UserAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeEmployees.add
        )
      );
    },
    meta: {
      pageName: i18n.t("employees.add"),
      pageIcon: "employees.svg",
      currentPage: "users",
      currentSubPage: "employees",
      userTypeToken: USER_TYPE.Employee,
      pageGet: "Employees",
      pageAdd: "EmployeeAdd",
      pageEdit: "EmployeeEdit",
    },
  },
  {
    path: "/users/employees/edit",
    name: "EmployeeEdit",
    component: () => import("../views/users/UserEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeEmployees.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("employees.edit"),
      pageIcon: "employees.svg",
      currentPage: "users",
      currentSubPage: "employees",
      userTypeToken: USER_TYPE.Employee,
      pageGet: "Employees",
      pageAdd: "EmployeeAdd",
      pageEdit: "EmployeeEdit",
    },
  },
  //#endregion Employees

  //#region Clients
  {
    path: "/users/clients",
    name: "Clients",
    component: () => import("../views/users/Clients.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeClients.viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("clients.modelName"),
      pageIcon: "clients.svg",
      currentPage: "users",
      currentSubPage: "clients",
      userTypeToken: USER_TYPE.Client,
      pageGet: "Clients",
      pageAdd: "ClientAdd",
      pageEdit: "ClientEdit",
    },
  },
  {
    path: "/users/clients/add",
    name: "ClientAdd",
    component: () => import("../views/users/UserAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeClients.add
        )
      );
    },
    meta: {
      pageName: i18n.t("clients.add"),
      pageIcon: "clients.svg",
      currentPage: "users",
      currentSubPage: "clients",
      userTypeToken: USER_TYPE.Client,
      pageGet: "Clients",
      pageAdd: "ClientAdd",
      pageEdit: "ClientEdit",
    },
  },
  {
    path: "/users/clients/edit",
    name: "ClientEdit",
    component: () => import("../views/users/UserEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeClients.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("clients.edit"),
      pageIcon: "clients.svg",
      currentPage: "users",
      currentSubPage: "clients",
      userTypeToken: USER_TYPE.Client,
      pageGet: "Clients",
      pageAdd: "ClientAdd",
      pageEdit: "ClientEdit",
    },
  },
  //#endregion Clients

  //#region Sellers
  {
    path: "/users/sellers",
    name: "Sellers",
    component: () => import("../views/users/Sellers.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeSeller.viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("sellers.modelName"),
      pageIcon: "sellers.svg",
      currentPage: "users",
      currentSubPage: "sellers",
      userTypeToken: USER_TYPE.Seller,
      pageGet: "Sellers",
      pageAdd: "SellerAdd",
      pageEdit: "SellerEdit",
    },
  },
  {
    path: "/users/sellers/add",
    name: "SellerAdd",
    component: () => import("../views/users/UserAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeSeller.add
        )
      );
    },
    meta: {
      pageName: i18n.t("sellers.add"),
      pageIcon: "sellers.svg",
      currentPage: "users",
      currentSubPage: "sellers",
      userTypeToken: USER_TYPE.Seller,
      pageGet: "Sellers",
      pageAdd: "SellerAdd",
      pageEdit: "SellerEdit",
    },
  },
  {
    path: "/users/sellers/edit",
    name: "SellerEdit",
    component: () => import("../views/users/UserEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeSeller.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("sellers.edit"),
      pageIcon: "sellers.svg",
      currentPage: "users",
      currentSubPage: "sellers",
      userTypeToken: USER_TYPE.Seller,
      pageGet: "Sellers",
      pageAdd: "SellerAdd",
      pageEdit: "SellerEdit",
    },
  },
  //#endregion Sellers
  //#endregion Users

  //#region The Cards
  //#region CardPlans
  {
    path: "/cards-plans",
    name: "CardPlans",
    component: () => import("../views/cardPlans/CardPlans.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeCardCreationPlans
            .viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("CardPlans.modelName"),
      pageIcon: "cardPlans.svg",
      currentPage: "theCards",
      currentSubPage: "cardPlans",
    },
  },
  {
    path: "/cards-plans/add",
    name: "CardPlanAdd",
    component: () => import("../views/cardPlans/CardPlanAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeCardCreationPlans.add
        )
      );
    },
    meta: {
      pageName: i18n.t("CardPlans.add"),
      pageIcon: "cardPlans.svg",
      currentPage: "theCards",
      currentSubPage: "cardPlans",
    },
  },
  //#endregion CardPlans

  //#region Cards
  {
    path: "/cards",
    name: "Cards",
    component: () => import("../views/cards/Cards.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeCards.viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("Cards.modelName"),
      pageIcon: "cards.svg",
      currentPage: "theCards",
      currentSubPage: "cards",
    },
  },
  //#endregion Cards

  //#region SellCards
  {
    path: "/sell-cards",
    name: "SellCards",
    component: () => import("../views/sellCards/SellCards.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeSellCards.viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("SellCards.modelName"),
      pageIcon: "sellCards.svg",
      currentPage: "theCards",
      currentSubPage: "sellCards",
    },
  },
  {
    path: "/sell-cards/add",
    name: "SellCardAdd",
    component: () => import("../views/sellCards/SellCardAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeSellCards.sellCards
        )
      );
    },
    meta: {
      pageName: i18n.t("SellCards.add"),
      pageIcon: "sellCards.svg",
      currentPage: "theCards",
      currentSubPage: "sellCards",
    },
  },
  //#endregion SellCards
  //#endregion The Cards

  //#region ActivityTypes
  {
    path: "/activity-types",
    name: "ActivityTypes",
    component: () => import("../views/activityTypes/ActivityTypes.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeActivityTypes.viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("activityTypes.modelName"),
      pageIcon: "activity-types.svg",
      currentPage: "activityTypes",
      currentSubPage: "",
    },
  },
  {
    path: "/add-activity-type/",
    name: "ActivityTypeAdd",
    component: () => import("../views/activityTypes/ActivityTypeAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeActivityTypes.add
        )
      );
    },
    meta: {
      pageName: i18n.t("activityTypes.add"),
      pageIcon: "activity-types.svg",
      currentPage: "activityTypes",
      currentSubPage: "",
    },
  },
  {
    path: "/edit-activity-type/",
    name: "ActivityTypeEdit",
    component: () => import("../views/activityTypes/ActivityTypeEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeActivityTypes.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("activityTypes.edit"),
      pageIcon: "activity-types.svg",
      currentPage: "activityTypes",
      currentSubPage: "",
    },
  },
  //#endregion ActivityTypes

  //#region Sponsors
  {
    path: "/sponsors",
    name: "Sponsors",
    component: () => import("../views/sponsors/Sponsors.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeSponsors.viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("sponsors.modelName"),
      pageIcon: "sponsors.svg",
      currentPage: "sponsors",
      currentSubPage: "",
    },
  },
  {
    path: "/add-sponsor/",
    name: "SponsorAdd",
    component: () => import("../views/sponsors/SponsorAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeSponsors.add
        )
      );
    },
    meta: {
      pageName: i18n.t("sponsors.add"),
      pageIcon: "sponsors.svg",
      currentPage: "sponsors",
      currentSubPage: "",
    },
  },
  {
    path: "/edit-sponsor/",
    name: "SponsorEdit",
    component: () => import("../views/sponsors/SponsorEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeSponsors.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("sponsors.edit"),
      pageIcon: "sponsors.svg",
      currentPage: "sponsors",
      currentSubPage: "",
    },
  },
  //#endregion Sponsors

  //#region QrCodeSession
  {
    path: "/qr-code-session-enquiry",
    name: "QrCodeSessionEnquiry",
    component: () => import("../views/qrCodeSessions/QrCodeSessionEnquiry.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeQrCodeSessions
            .enquiryQrCodeVCD
        )
      );
    },
    meta: {
      pageName: i18n.t("QR.QREnquiry"),
      pageIcon: "qr-code-enquiry.svg",
      currentPage: "QrCode",
      currentSubPage: "QrCodeSessionEnquiry",
    },
  },
  {
    path: "/qr-code-session-generate",
    name: "QrCodeSessionGenerate",
    component: () =>
      import("../views/qrCodeSessions/QrCodeSessionGenerate.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeQrCodeSessions
            .generateMyQrCodeVCD
        )
      );
    },
    meta: {
      pageName: i18n.t("QR.QRGenerate"),
      pageIcon: "qr-code-generate.svg",
      currentPage: "QrCode",
      currentSubPage: "QrCodeSessionGenerate",
    },
  },
  //#endregion QrCodeSession

  //#region Brands
  {
    path: "/brands",
    name: "Brands",
    component: () => import("../views/brands/Brands.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrands.viewData
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("brands.modelName"),
      pageIcon: "brand.svg",
      currentPage: "theBrands",
      currentSubPage: "brands",
    },
  },
  {
    path: "/brands/add",
    name: "BrandAdd",
    component: () => import("../views/brands/BrandAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrands.add
        )
      );
    },
    meta: {
      pageName: i18n.t("brands.add"),
      pageIcon: "brand.svg",
      currentPage: "theBrands",
      currentSubPage: "brands",
    },
  },
  {
    path: "/brands/edit",
    name: "BrandEdit",
    component: () => import("../views/brands/BrandEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrands.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("brands.edit"),
      pageIcon: "brand.svg",
      currentPage: "theBrands",
      currentSubPage: "brands",
    },
  },
  //#endregion Brands

  //#region BrandServices
  {
    path: "/brand-services",
    name: "BrandServices",
    component: () => import("../views/brandServices/BrandServices.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrandServices.viewData
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("brandServices.modelName"),
      pageIcon: "brand-services.svg",
      currentPage: "theBrands",
      currentSubPage: "brandServices",
    },
  },
  {
    path: "/brand-services/add",
    name: "BrandServiceAdd",
    component: () => import("../views/brandServices/BrandServiceAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrandServices.add
        )
      );
    },
    meta: {
      pageName: i18n.t("brandServices.add"),
      pageIcon: "brand-services.svg",
      currentPage: "theBrands",
      currentSubPage: "brandServices",
    },
  },
  {
    path: "/brand-services/edit",
    name: "BrandServiceEdit",
    component: () => import("../views/brandServices/BrandServiceEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrandServices.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("brandServices.edit"),
      pageIcon: "brand-services.svg",
      currentPage: "theBrands",
      currentSubPage: "brandServices",
    },
  },
  //#endregion BrandServices

  //#region BrandPlaces
  {
    path: "/brand-places",
    name: "BrandPlaces",
    component: () => import("../views/brandPlaces/BrandPlaces.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrandPlaces.viewData
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("brandPlaces.modelName"),
      pageIcon: "brand-places.svg",
      currentPage: "theBrands",
      currentSubPage: "brandPlaces",
    },
  },
  {
    path: "/brand-places/add",
    name: "BrandPlaceAdd",
    component: () => import("../views/brandPlaces/BrandPlaceAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrandPlaces.add
        )
      );
    },
    meta: {
      pageName: i18n.t("brandPlaces.add"),
      pageIcon: "brand-places.svg",
      currentPage: "theBrands",
      currentSubPage: "brandPlaces",
    },
  },
  {
    path: "/brand-places/edit",
    name: "BrandPlaceEdit",
    component: () => import("../views/brandPlaces/BrandPlaceEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrandPlaces.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("brandPlaces.edit"),
      pageIcon: "brand-places.svg",
      currentPage: "theBrands",
      currentSubPage: "brandPlaces",
    },
  },
  //#endregion BrandPlaces

  //#region BrandUsers
  {
    path: "/brand-users",
    name: "BrandUsers",
    component: () => import("../views/brandUsers/BrandUsers.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrandUsers.viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("BrandUsers.modelName"),
      pageIcon: "brandUsers.svg",
      currentPage: "theBrands",
      currentSubPage: "brandUsers",
    },
  },
  {
    path: "/brand-users/add",
    name: "BrandUserAdd",
    component: () => import("../views/brandUsers/BrandUserAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrandUsers.add
        )
      );
    },
    meta: {
      pageName: i18n.t("BrandUsers.add"),
      pageIcon: "brandUsers.svg",
      currentPage: "theBrands",
      currentSubPage: "brandUsers",
    },
  },
  {
    path: "/brand-users/edit",
    name: "BrandUserEdit",
    component: () => import("../views/brandUsers/BrandUserEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrandUsers.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("BrandUsers.edit"),
      pageIcon: "brandUsers.svg",
      currentPage: "theBrands",
      currentSubPage: "brandUsers",
    },
  },
  //#endregion BrandUsers

  //#region Offers
  {
    path: "/offers",
    name: "Offers",
    component: () => import("../views/offers/Offers.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeOffers.viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("offers"),
      pageIcon: "offers.svg",
      currentPage: "offers",
      currentSubPage: "",
    },
  },
  {
    path: "/offers/add",
    name: "OfferAdd",
    component: () => import("../views/offers/OfferAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeOffers.add
        )
      );
    },
    meta: {
      pageName: i18n.t("offerAdd"),
      pageIcon: "offers.svg",
      currentPage: "offers",
      currentSubPage: "",
    },
  },
  {
    path: "/offers/edit",
    name: "OfferEdit",
    component: () => import("../views/offers/OfferEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeOffers.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("offerEdit"),
      pageIcon: "offers.svg",
      currentPage: "offers",
      currentSubPage: "",
    },
  },
  //#endregion Offers

  //#region Reports
  {
    path: "/reports",
    name: "Reports",
    component: () => import("../views/reports/Reports.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeBrands.report
        ) ||
          checkPrivilege(
            store.getters.usersPrivilegeData.usersPrivilegeCards.report
          ) ||
          checkPrivilege(
            store.getters.usersPrivilegeData.usersPrivilegeSellCards.report
          )
      );
    },
    meta: {
      pageName: i18n.t("reports"),
      pageIcon: "reports.svg",
      currentPage: "reports",
      currentSubPage: "",
    },
  },
  //#endregion Reports

  //#region Settings
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/settings/Settings.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeEstablishmentRoles
            .viewData
        ) ||
          checkPrivilege(
            store.getters.usersPrivilegeData.usersPrivilegeUserValidSetting
              .viewData
          )
      );
    },
    meta: {
      pageName: i18n.t("settings"),
      pageIcon: "settings.svg",
      currentPage: "settings",
      currentSubPage: "",
    },
  },

  //#region EstablishmentRoles
  {
    path: "/settings/establishment-roles",
    name: "EstablishmentRoles",
    component: () =>
      import("../views/settings/establishmentRoles/EstablishmentRoles.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeEstablishmentRoles
            .viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("EstablishmentRoles.modelName"),
      pageIcon: "establishment-role.svg",
      currentPage: "settings",
      currentSubPage: "",
    },
  },
  {
    path: "/settings/establishment-roles/add",
    name: "EstablishmentRoleAdd",
    component: () =>
      import("../views/settings/establishmentRoles/EstablishmentRoleAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeEstablishmentRoles.add
        )
      );
    },
    meta: {
      pageName: i18n.t("EstablishmentRoles.add"),
      pageIcon: "establishment-role.svg",
      currentPage: "settings",
      currentSubPage: "",
    },
  },
  {
    path: "/settings/establishment-roles/edit",
    name: "EstablishmentRoleEdit",
    component: () =>
      import("../views/settings/establishmentRoles/EstablishmentRoleEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeEstablishmentRoles
            .update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("EstablishmentRoles.edit"),
      pageIcon: "establishment-role.svg",
      currentPage: "settings",
      currentSubPage: "",
    },
  },
  //#endregion EstablishmentRoles

  //#region UserValidSettings
  {
    path: "/settings/user-valid-settings",
    name: "UserValidSettings",
    component: () =>
      import("../views/settings/userValidSettings/UserValidSettings.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeUserValidSetting
            .viewData
        )
      );
    },
    meta: {
      pageName: i18n.t("UserValidSettings.modelName"),
      pageIcon: "UserValidSettings.svg",
      currentPage: "settings",
      currentSubPage: "",
    },
  },
  {
    path: "/settings/user-valid-settings/add",
    name: "UserValidSettingAdd",
    component: () =>
      import("../views/settings/userValidSettings/UserValidSettingAdd.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeUserValidSetting.add
        )
      );
    },
    meta: {
      pageName: i18n.t("UserValidSettings.add"),
      pageIcon: "UserValidSettings.svg",
      currentPage: "settings",
      currentSubPage: "",
    },
  },
  {
    path: "/settings/user-valid-settings/edit",
    name: "UserValidSettingEdit",
    component: () =>
      import("../views/settings/userValidSettings/UserValidSettingEdit.vue"),
    beforeEnter: (to, from, next) => {
      handlePrivilege(
        to,
        from,
        next,
        checkPrivilege(
          store.getters.usersPrivilegeData.usersPrivilegeUserValidSetting.update
        )
      );
    },
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("UserValidSettings.edit"),
      pageIcon: "UserValidSettings.svg",
      currentPage: "settings",
      currentSubPage: "",
    },
  },
  //#endregion UserValidSettings

  //#endregion Settings

  //#region Error
  {
    path: "/error-404",
    name: "Error404",
    component: () => import("../views/errors/Error-404.vue"),
    meta: {
      pageName: i18n.t("pageNotFound"),
      pageIcon: "404-icon.svg",
      currentPage: "",
      currentSubPage: "",
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: { name: "Error404" },
  },
  //#endregion Error
];

export default routes;
