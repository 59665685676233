<template>
  <ul>
    <!-- #region ActivityTypes -->
    <li
      v-if="
        checkPrivilege(usersPrivilegeData.usersPrivilegeActivityTypes.viewData)
      "
    >
      <router-link
        :to="{ name: 'ActivityTypes' }"
        :class="currentPage == 'activityTypes' ? 'active' : ''"
      >
        <span class="icon"
          ><img src="@/assets/images/activity-types.svg"
        /></span>
        <span class="title">{{ $t("activityTypes.modelName") }}</span>
      </router-link>
    </li>
    <!-- #endregion ActivityTypes -->

    <!-- #region theBrands -->
    <li
      v-if="
        checkPrivilege(usersPrivilegeData.usersPrivilegeBrands.viewData) ||
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeBrandServices.viewData
        ) ||
        checkPrivilege(usersPrivilegeData.usersPrivilegeBrandPlaces.viewData) ||
        checkPrivilege(usersPrivilegeData.usersPrivilegeBrandUsers.viewData)
      "
      class="sub-menu"
    >
      <a
        v-b-toggle.theBrands
        :class="currentPage == 'theBrands' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/brand.svg" /></span>
        <span class="title">
          {{ $t("theBrands") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="theBrands">
        <ul>
          <!-- #region Brands -->
          <li
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeBrands.viewData)
            "
          >
            <router-link
              :to="{ name: 'Brands' }"
              :class="currentSubPage == 'brands' ? 'active' : ''"
            >
              <span class="icon"><img src="@/assets/images/brand.svg" /></span>
              <span class="title">{{ $t("brands.modelName") }}</span>
            </router-link>
          </li>
          <!-- #endregion Brands -->

          <!-- #region BrandServices -->
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeBrandServices.viewData
              )
            "
          >
            <router-link
              :to="{ name: 'BrandServices' }"
              :class="currentSubPage == 'brandServices' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/brand-services.svg"
              /></span>
              <span class="title">{{ $t("brandServices.modelName") }}</span>
            </router-link>
          </li>
          <!-- #endregion BrandServices -->

          <!-- #region BrandPlaces -->
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeBrandPlaces.viewData
              )
            "
          >
            <router-link
              :to="{ name: 'BrandPlaces' }"
              :class="currentSubPage == 'brandPlaces' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/brand-places.svg"
              /></span>
              <span class="title">{{ $t("brandPlaces.modelName") }}</span>
            </router-link>
          </li>
          <!-- #endregion BrandPlaces -->

          <!-- #region BrandUsers -->
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeBrandUsers.viewData
              )
            "
          >
            <router-link
              :to="{ name: 'BrandUsers' }"
              :class="currentSubPage == 'brandUsers' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/brandUsers.svg"
              /></span>
              <span class="title">{{ $t("BrandUsers.modelName") }}</span>
            </router-link>
          </li>
          <!-- #endregion BrandUsers -->
        </ul>
      </b-collapse>
    </li>
    <!-- #endregion theBrands -->

    <!-- #region Sponsors -->
    <li
      v-if="checkPrivilege(usersPrivilegeData.usersPrivilegeSponsors.viewData)"
    >
      <router-link
        :to="{ name: 'Sponsors' }"
        :class="currentPage == 'sponsors' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/sponsors.svg" /></span>
        <span class="title">{{ $t("sponsors.modelName") }}</span>
      </router-link>
    </li>
    <!-- #endregion Sponsors -->

    <!-- #region Offers -->
    <li v-if="checkPrivilege(usersPrivilegeData.usersPrivilegeOffers.viewData)">
      <router-link
        :to="{ name: 'Offers' }"
        :class="currentPage == 'offers' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/offers.svg" /></span>
        <span class="title">{{ $t("offers") }}</span>
      </router-link>
    </li>
    <!-- #endregion Offers -->

    <!-- #region QrCode -->
    <li
      class="sub-menu"
      v-if="
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeQrCodeSessions.enquiryQrCodeVCD
        ) ||
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeQrCodeSessions.generateMyQrCodeVCD
        )
      "
    >
      <a v-b-toggle.QrCode :class="currentPage == 'QrCode' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/qr-code.svg" /></span>
        <span class="title">
          {{ $t("QR.modelName") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="QrCode">
        <ul>
          <!-- #region QrCodeEnquiry -->
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeQrCodeSessions.enquiryQrCodeVCD
              )
            "
          >
            <router-link
              :to="{ name: 'QrCodeSessionEnquiry' }"
              :class="currentSubPage == 'QrCodeSessionEnquiry' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/qr-code-enquiry.svg"
              /></span>
              <span class="title">{{ $t("QR.QREnquiry") }}</span>
            </router-link>
          </li>
          <!-- #endregion QrCodeEnquiry -->

          <!-- #region QrCodeGenerate -->
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeQrCodeSessions
                  .generateMyQrCodeVCD
              )
            "
          >
            <router-link
              :to="{ name: 'QrCodeSessionGenerate' }"
              :class="currentSubPage == 'QrCodeSessionGenerate' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/qr-code-generate.svg"
              /></span>
              <span class="title">{{ $t("QR.QRGenerate") }}</span>
            </router-link>
          </li>
          <!-- #endregion QrCodeGenerate -->
        </ul>
      </b-collapse>
    </li>
    <!-- #endregion QrCode -->

    <!-- #region TheCards -->
    <li
      v-if="
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeCardCreationPlans.viewData
        ) ||
        checkPrivilege(usersPrivilegeData.usersPrivilegeCards.viewData) ||
        checkPrivilege(usersPrivilegeData.usersPrivilegeSellCards.viewData)
      "
      class="sub-menu"
    >
      <a v-b-toggle.theCards :class="currentPage == 'theCards' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/theCards.svg" /></span>
        <span class="title">
          {{ $t("theCards.modelName") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="theCards">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeCardCreationPlans.viewData
              )
            "
          >
            <router-link
              :to="{ name: 'CardPlans' }"
              :class="currentSubPage == 'cardPlans' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/cardPlans.svg"
              /></span>
              <span class="title">{{ $t("CardPlans.modelName") }}</span>
            </router-link>
          </li>

          <li
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeCards.viewData)
            "
          >
            <router-link
              :to="{ name: 'Cards' }"
              :class="currentSubPage == 'cards' ? 'active' : ''"
            >
              <span class="icon"><img src="@/assets/images/cards.svg" /></span>
              <span class="title">{{ $t("Cards.modelName") }}</span>
            </router-link>
          </li>

          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeSellCards.viewData
              )
            "
          >
            <router-link
              :to="{ name: 'SellCards' }"
              :class="currentSubPage == 'sellCards' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/sellCards.svg"
              /></span>
              <span class="title">{{ $t("SellCards.modelName") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>
    <!-- #endregion TheCards -->

    <!-- #region Users -->
    <li
      v-if="
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeMasterAdmins.viewData
        ) ||
        checkPrivilege(usersPrivilegeData.usersPrivilegeEmployees.viewData) ||
        checkPrivilege(usersPrivilegeData.usersPrivilegeClients.viewData) ||
        checkPrivilege(usersPrivilegeData.usersPrivilegeSeller.viewData)
      "
      class="sub-menu"
    >
      <a v-b-toggle.users :class="currentPage == 'users' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/users.svg" /></span>
        <span class="title">
          {{ $t("users.modelName") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="users">
        <ul>
          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeMasterAdmins.viewData
              )
            "
          >
            <router-link
              :to="{ name: 'MasterAdmins' }"
              :class="currentSubPage == 'masterAdmins' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/masterAdmins.svg"
              /></span>
              <span class="title">{{ $t("masterAdmins.modelName") }}</span>
            </router-link>
          </li>

          <li
            v-if="
              checkPrivilege(
                usersPrivilegeData.usersPrivilegeEmployees.viewData
              )
            "
          >
            <router-link
              :to="{ name: 'Employees' }"
              :class="currentSubPage == 'employees' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/employees.svg"
              /></span>
              <span class="title">{{ $t("employees.modelName") }}</span>
            </router-link>
          </li>

          <li
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeClients.viewData)
            "
          >
            <router-link
              :to="{ name: 'Clients' }"
              :class="currentSubPage == 'clients' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/clients.svg"
              /></span>
              <span class="title">{{ $t("clients.modelName") }}</span>
            </router-link>
          </li>

          <li
            v-if="
              checkPrivilege(usersPrivilegeData.usersPrivilegeSeller.viewData)
            "
          >
            <router-link
              :to="{ name: 'Sellers' }"
              :class="currentSubPage == 'sellers' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/sellers.svg"
              /></span>
              <span class="title">{{ $t("sellers.modelName") }}</span>
            </router-link>
          </li>
        </ul>
      </b-collapse>
    </li>
    <!-- #endregion Users -->

    <!-- #region Reports -->
    <li
      v-if="
        checkPrivilege(usersPrivilegeData.usersPrivilegeBrands.report) ||
        checkPrivilege(usersPrivilegeData.usersPrivilegeCards.report) ||
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeQrCodeSessions.qrCodeStatisticsReport
        ) ||
        checkPrivilege(usersPrivilegeData.usersPrivilegeSellCards.report)
      "
    >
      <router-link
        :to="{ name: 'Reports' }"
        :class="currentPage == 'reports' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/reports.svg" /></span>
        <span class="title">{{ $t("reports") }}</span>
      </router-link>
    </li>
    <!-- #endregion Reports -->

    <!-- #region Settings -->
    <li
      v-if="
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeEstablishmentRoles.viewData
        ) ||
        checkPrivilege(
          usersPrivilegeData.usersPrivilegeUserValidSetting.viewData
        )
      "
    >
      <router-link
        :to="{ name: 'Settings' }"
        :class="currentPage == 'settings' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/settings.svg" /></span>
        <span class="title">{{ $t("settings") }}</span>
      </router-link>
    </li>
    <!-- #endregion Settings -->
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import { checkPrivilege } from "@/utils/functions";

export default {
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
      currentPage: "",
      currentSubPage: "",
    };
  },
  methods: {
    getPageInfo() {
      this.currentPage = this.$route.meta.currentPage || "";
      this.currentSubPage = this.$route.meta.currentSubPage || "";
    },
    checkPrivilege,
  },
  watch: {
    $route() {
      this.getPageInfo();
    },
  },
  async created() {
    this.getPageInfo();
  },
};
</script>
