export default class GeneralPrivilege {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.view = false;
    this.viewSelfData = false;
    this.viewData = false;
    this.viewDialog = false;
    this.add = false;
    this.update = false;
    this.archive = false;
    this.restore = false;
    this.deleteImage = false;
    this.delete = false;
    this.notification = false;
  }
  fillData(data) {
    if (data) {
      this.view = data.view;
      this.viewSelfData = data.viewSelfData;
      this.viewData = data.viewData;
      this.viewDialog = data.viewDialog;
      this.add = data.add;
      this.update = data.update;
      this.archive = data.archive;
      this.restore = data.restore;
      this.deleteImage = data.deleteImage;
      this.delete = data.delete;
      this.notification = data.notification;
    } else {
      this.setInitialValue();
    }
  }

  selectAll(status) {
    this.view = status;
    this.viewSelfData = status;
    this.viewData = status;
    this.viewDialog = status;
    this.add = status;
    this.update = status;
    this.archive = status;
    this.restore = status;
    this.deleteImage = status;
    this.delete = status;
    this.notification = status;
  }
}
