export default class UsersPrivilegeSellCards {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.view = false;
    this.viewData = false;
    this.viewDialog = false;
    this.sellCards = false;
    this.notification = false;
    this.report = false;
  }
  fillData(data) {
    if (data) {
      this.view = data.view;
      this.viewData = data.viewData;
      this.viewDialog = data.viewDialog;
      this.sellCards = data.sellCards;
      this.notification = data.notification;
      this.report = data.report;
    } else {
      this.setInitialValue();
    }
  }

  selectAll(status) {
    this.view = status;
    this.viewData = status;
    this.viewDialog = status;
    this.sellCards = status;
    this.notification = status;
    this.report = status;
  }
}
