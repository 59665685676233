// import i18n from "@/i18n";

// #region USERS
export const USER_TYPE = {
  AdminsEgypt: "UTT-12300", // AdminsEgypt أدمنز إيجيبت
  MasterAdmin: "UTT-12400", // MasterAdmin مالك النظام
  Employee: "UTT-12500", // Employee موظف
  Client: "UTT-12600", // Client عميل
  Seller: "UTT-12900", // Seller بائع
};
// #endregion USERS
