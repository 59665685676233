export const BASE_URL = "https://api-savecard-dev-v1.0.save-card.com";
// export const BASE_URL = "https://api-vcd-dev-v2.premcoprecast.com";
// export const BASE_URL = "https://api-vcd-dev.premcoprecast.com";
// export const BASE_URL = "https://api-savecard-v1.0.save-card.com";
// export const BASE_URL = "https://api-pls-dev.premcoprecast.com";

import { getDeviceName, getBrowserName } from "@/utils/functions";

export const USER_PLATFORM_SESSION = {
  userPlatFormSessionVersionNumber: "1.0",
  userPlatFormSessionLocalIP: "",
  userPlatFormSessionGlobalIP: "",
  userPlatFormSessionAddress: "",
  userPlatFormSessionLat: "",
  userPlatFormSessionLong: "",
  userPlatFormToken: "PFT-3",
  userFirebaseToken: "userFirebaseToken",
};
export const USER_DEVICE = {
  userDeviceUniqueCode: "WEB",
  userDeviceId: "",
  userDeviceName: "Unkonwn",
  userDeviceCompany: "",
  userDeviceOS: "",
  userDeviceVersionOS: getBrowserName() + " " + getDeviceName(),
  userDeviceEMUI: "",
  userDeviceRam: "",
  userDeviceProcessor: "",
  userDeviceDisplay: "",
  userDeviceDescription: "",
  userDeviceNotes: "",
};

export const STATUS = {
  SUCCESS: 200, // success operation
  INVALID_TOKEN: 498, //invalid token
  CATCH: 500, // catch
  NO_CONTENT: 204, // no data
  ERROR: 406, // validation
  VERSION: 306, // error version
};

export const DEFAULT_PAGES = {
  notLoggedIn: "Home",
  loggedIn: "ActivityTypes",
};

export const PREFER_LIST = {
  prefered: "PTT-1",
  notPrefered: "PTT-2",
};

export const LANGUAGES = {
  default: "ar",
  list: ["ar", "en", "und"],
};
export const ESTABLISHMENT_TOKEN = "f92dfb74-9cb9-44ad-b5ad-a946c3d1018f";

export const PAGE_SIZE = 16;
export const IMPOSSIBLE_TOKEN = 0;
export const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 50;
export const TEXT_MAX_LENGTH = 500;
export const TEXT_MAX_LENGTH_SUPER = 1000000;
export const MAX_ROWS_TEXTAREA = 100;

export const NEWS_MEDIA_TYPE_TOKEN_IMAGE = "MET-1";
export const NEWS_MEDIA_TYPE_TOKEN_VIDEO = "MET-2";
export const NEWS_MEDIA_TYPE_TOKEN_AUDIO = "MET-3";
export const NEWS_MEDIA_TYPE_TOKEN_PDF = "MET-4";
export const NEWS_MEDIA_TYPE_TOKEN_EXCEL = "MET-5";
export const NEWS_MEDIA_TYPE_TOKEN_WORD = "MET-6";
export const NEWS_MEDIA_TYPE_TOKEN_POWER_POINT = "MET-7";

export const IMAGE_EXTENSIONS = [
  "jpeg",
  "jpg",
  "gif",
  "tiff",
  "psd",
  "tiff",
  "eps",
  "ai",
  "aidd",
  "raw",
];
export const VIDEO_EXTENSIONS = [
  "mov",
  "avi",
  "wmv",
  "mp4",
  "m4p",
  "m4v",
  "mpg",
  "mp2",
  "mpeg",
  "mpe",
  "mpv",
  "3gp",
  "flv",
];
export const AUDIO_EXTENSIONS = [
  "aif",
  "cda",
  "mid",
  "mp3",
  "mpa",
  "ogg",
  "wav",
  "wma",
  "wpl",
];
export const PDF_EXTENSIONS = ["pdf"];
export const EXCEL_EXTENSIONS = ["xlsx", "xlsm", "xlsb", "xltx"];
export const WORD_EXTENSIONS = ["doc", "docm", "docx"];
export const POWER_POINT_EXTENSIONS = ["ppsx", "ppt", "pptm", "pptx"];
