import { checkLoginForShowSidebar } from "./../../utils/functions";
import { DEFAULT_PAGES } from "./../../utils/constants";
import router from "./../../router";
import EstablishmentRole from "./../../models/settings/establishmentRoles/EstablishmentRole";

export default {
  state: {
    userAuthorizeToken: null,
    userData: null,
    constantsListsData: null,
    usersPrivilegeData: null,
    userHomePage: "",
  },
  getters: {
    userAuthorizeToken: (state) => state.userAuthorizeToken,
    userData: (state) => state.userData,
    userToken: (state) => state.userData.userToken,
    constantsListsData: (state) => state.constantsListsData,
    usersPrivilegeData: (state) => state.usersPrivilegeData,
    userHomePage: (state) => state.userHomePage,
  },
  mutations: {
    UPDATE_USER_DATA: (state, apiAppData) => {
      state.userAuthorizeToken = apiAppData.userAuthorizeToken || null;
      state.userData = apiAppData.userData || null;
      state.constantsListsData = apiAppData.constantsListsData || null;
      state.usersPrivilegeData = new EstablishmentRole();
      state.usersPrivilegeData.fillData(
        apiAppData.userData.establishmentRoleData
      );
      checkLoginForShowSidebar();
    },
    LOGOUT_USER: (state) => {
      state.userAuthorizeToken = null;
      state.userData = null;
      state.constantsListsData = null;
      state.usersPrivilegeData = null;
      checkLoginForShowSidebar();
    },
    SET_USER_HOME_PAGE: (state, userHomePage) => {
      state.userHomePage = userHomePage;
    },
  },
  actions: {
    loginUser(context, apiAppData) {
      context.commit("UPDATE_USER_DATA", apiAppData);
    },
    logoutUser(context) {
      context.commit("LOGOUT_USER");
      router.push({ name: DEFAULT_PAGES.notLoggedIn }).catch(() => {});
    },
    setUserHomePage(context, userHomePage) {
      context.commit("SET_USER_HOME_PAGE", userHomePage);
    },
  },
  modules: {},
};
