export default class UsersPrivilegeNotifications {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.notificationView = false;
    this.notificationRead = false;
    this.notificationOpen = false;
    this.notificationArchive = false;
    this.notificationAdd = false;
    this.notificationAddGenral = false;
  }
  fillData(data) {
    if (data) {
      this.notificationView = data.notificationView;
      this.notificationRead = data.notificationRead;
      this.notificationOpen = data.notificationOpen;
      this.notificationArchive = data.notificationArchive;
      this.notificationAdd = data.notificationAdd;
      this.notificationAddGenral = data.notificationAddGenral;
    } else {
      this.setInitialValue();
    }
  }

  selectAll(status) {
    this.notificationView = status;
    this.notificationRead = status;
    this.notificationOpen = status;
    this.notificationArchive = status;
    this.notificationAdd = status;
    this.notificationAddGenral = status;
  }
}
